<template>
  <PageCard pageName="Users" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <div class="row">
        <div class="col-sm-12 text-right">
          <b-button
            size=""
            variant="primary"
            class="fw-btn mb-3"
            :disabled="!this.pendingUsers.length"
            v-b-modal.reInvitePendingUsers
            v-b-tooltip.hover
            title="Send new invite to all non-registered users."
          >
            Re-invite All Users
          </b-button>
          <b-button
            size=""
            variant="primary"
            class="fw-btn mb-3 mx-3"
            :disabled="!this.roles.length"
            v-b-modal.inviteUser
            >Invite New User</b-button
          >
          <b-button
            size=""
            variant="primary"
            class="fw-btn  mb-3"
            :disabled="!this.roles.length"
            v-b-modal.bulkInviteUsers
          >
            Bulk Invite Users
          </b-button>
        </div>
      </div>
      <b-row class="search-container--users">
        <b-col sm="12">
          <div class="search-container--bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form-input
                v-focus
                id="prependedInput"
                class="form-control no-focus-highlt"
                size="lg"
                type="text"
                placeholder="Type User Name to Select"
                @keyup.native="search"
                v-model="searchText"
              >
              </b-form-input>
            </b-input-group>
          </div>
          <div class="search-container--result std-border">
            <div>
              <v-wait for="fetchingUsers">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="15"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div class="text-left">
                  <b-list-group v-if="emptyUsers" class="text-center">
                    <b-list-group-item
                      ><i>No Users found ... </i></b-list-group-item
                    >
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(user, index) in filteredUsers" :key="index">
                      <b-list-group-item
                        @click="selectUser(user)"
                        :class="[
                          { selected: user.id === selectedUser.id },
                          'p-2',
                          'pl-4'
                        ]"
                      >
                        {{ user.displayName }}
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </div>
              </v-wait>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-center pt-1">
        <b-col md="12">
          <b-button
            size=""
            variant="primary"
            class="fw-btn mt-3 mx-3"
            :disabled="!enableReinviate"
            v-b-modal.reInviteConfirmation
            >Re-invite User</b-button
          >
          <router-link
            tag="button"
            :to="{ path: `user/${selectedUser.id}/edit` }"
            class="btn btn-primary fw-btn mt-3 mx-3"
            :disabled="!selectedUser.id"
          >
            Edit User
          </router-link>
          <b-button
            size=""
            variant="secondary"
            :disabled="!selectedUser.id"
            class="fw-btn mt-3 mx-3"
            v-b-modal.deleteConfirmation
            >Delete User</b-button
          >
        </b-col>
      </b-row>

      <InviteUser :roles="roles" @userAdded="fetchUsers"></InviteUser>
      <BulkInviteUsers :roles="roles" @userAdded="fetchUsers">
      </BulkInviteUsers>
      <b-modal
        id="reInviteConfirmation"
        ref="reInviteConfirmation"
        title="ReInvite User"
        @ok="resendUserInvite"
      >
        <p>Are you sure, want to resend invite for this user?</p>
      </b-modal>
      <b-modal
        id="deleteConfirmation"
        ref="deleteConfirmation"
        title="Delete User"
        @ok="deleteUser"
      >
        <p>Are you sure, want to delete the user?</p>
      </b-modal>

      <b-modal
        id="reInvitePendingUsers"
        ref="reInvitePendingUsers"
        title="Re-invite Pending Users"
        @ok="resendPendingUsersInvite"
        scrollable
        size="md"
      >
        <p>Invite email will be sent to the following users.</p>
        <b-list-group>
          <b-list-group-item
            v-for="(user, index) in pendingUsers"
            :key="index"
            class="py-1 px-2"
            >{{ user.first_name }} {{ user.last_name }} -
            {{ user.email }}</b-list-group-item
          >
        </b-list-group>
      </b-modal>
    </template>
  </PageCard>
</template>

<script>
// glboal
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import PageCard from '@/modules/core/components/layouts/PageCard'
import InviteUser from '../components/InviteUser'
import BulkInviteUsers from '../../admin/components/BulkInviteUsers'
// api
import usersAPI from '@/api/finapps/admin/users'

export default {
  name: 'AdminUsers',
  components: {
    PageCard,
    InviteUser,
    BulkInviteUsers
  },
  created() {
    //Empty any selected user on load
    this.setSelectedUser({})
    this.fetchUsers()
    this.fetchRoles()
  },
  data() {
    return {
      users: [],
      searchText: '',
      filteredUsers: [],
      roles: []
    }
  },
  computed: {
    ...mapGetters('Admin', ['selectedUser']),
    emptyUsers() {
      return this.users.length === 0
    },
    enableReinviate() {
      return this.selectedUser.id && !this.selectedUser.confirmed_at
    },
    pendingUsers() {
      return this.users.filter(user => !user.confirmed_at)
    }
  },
  methods: {
    ...mapMutations('Admin', ['setSelectedUser']),
    fetchUsers() {
      this.$wait.start('fetchingUsers')
      this.$http
        .get('/api/group_users', {
          handleErrors: true
        })
        .then(res => {
          this.users = this.formattedUserData(res.data.users)
          this.filterSuperAdminUsers()
          this.filteredUsers = this.users
          if (
            this.emptyUsers ||
            (this.selectedUser &&
              this.users.filter(user => user.id === this.selectedUser.id)
                .length == 0)
          )
            this.setSelectedUser({})
        })
        .then(() => {
          this.$wait.end('fetchingUsers')
        })
    },
    formattedUserData(users) {
      users.forEach(user => {
        user.displayName =
          user.email + ' - ' + user.first_name + ' ' + user.last_name
      })
      return users
    },
    search(event) {
      let text = event.target.value.toLowerCase()
      if (this.text === '') {
        this.filteredUsers = this.users
      } else {
        this.filteredUsers = this.users.filter(user => {
          return user.displayName.toLowerCase().indexOf(text) >= 0
        })
      }
    },
    selectUser: function(user) {
      this.searchText = user.displayName
      this.setSelectedUser(user)
    },
    fetchRoles() {
      this.$http
        .get('/api/group_users/new', {
          handleErrors: true
        })
        .then(res => {
          this.roles = this.formatRoleData(res.data.roles)
        })
    },
    filterSuperAdminUsers() {
      this.users = this.users.filter(user => user.role_id != 1)
    },
    formatRoleData(roles) {
      // roles.forEach(role => {
      //   role.alias = role.name === 'Admin' ? 'Super Admin' : role.name
      // })
      roles.sort((a, b) => b.name.length - a.name.length)
      return roles
    },
    resendUserInvite() {
      return usersAPI.reInviteUser(this.selectedUser.id).then(() => {
        Vue.toasted.show('Invite email resent successfully.', {
          icon: 'user-circle',
          type: 'success'
        })
      })
    },
    resendPendingUsersInvite() {
      return usersAPI.reInvitePendingUsers().then(() => {
        Vue.toasted.show('Invite email resent for all pending users.', {
          icon: 'user-circle',
          type: 'success'
        })
      })
    },
    deleteUser() {
      this.$http
        .delete(`/api/group_users/${this.selectedUser.id}`, {
          handleErrors: true
        })
        .then(
          () => {
            this.$refs.deleteConfirmation.hide()
            this.setSelectedUser({})
            this.searchText = ''
            this.fetchUsers()
            Vue.toasted.show('User deleted successfully', {
              icon: 'user-circle',
              type: 'success'
            })
          },
          () => {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.temp {
  min-height: 35rem;
}
</style>
